<template>
  <div>
    AAA {{x}}
    <div v-for="y,i in x" v-bind:key="y.hash">
      <span @click="gotoHash(y.hash)">{{i}} - {{y}}</span>
    </div>
  </div>
</template>

<script>
  import {
    mapGetters,
    mapState,
  } from 'vuex'
  export default {
    name: 'GameResults',
    components: {
    },
    data () {
      return {
        x: {},
      }
    },
    computed: {
      ...mapState('wallet', ['address']),
      ...mapGetters('node', ['node']),
    },
    async mounted () {
      var ret = (await this.$http.get('https://' + this.node.host + '/gr?addr=' + this.address)).data
      this.x = ret
    },
    methods: {
      gotoHash (hash) {
        this.$router.push('/result/' + hash)
      },
    },
  }
</script>
